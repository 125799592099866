<template>
  <div class="about">
    <page-header title="A propos" icon="fa fa-info-circle"></page-header>
    <p>TODO</p>
  </div>
</template>
<script>
import PageHeader from '@/components/Layout/PageHeader'

export default {
  components: {
    PageHeader,
  },
}
</script>
